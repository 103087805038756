import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "errors"
    }}>{`Errors`}</h1>
    <p>{`We use standard HTTP response codes for success and failure. In general, 200 HTTP codes correspond to success, 40X codes are for developer- or user-related failures, and 50X codes are for internal API issues. The customer API has two different error responses dependent on the URI used. We are working on returning the same response for all our endpoints, but this will not happen before next version because we need to keep the API backward compatible.`}</p>
    <h2 {...{
      "id": "profiles-and-consents"
    }}>{`Profiles and consents`}</h2>
    <p>{`You can expect this error for URI starting with:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/profiles`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`/consents`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`{
     "errorCode": 0,
     "shortNorwegian": "string",
     "longNorwegian": "string",
     "shortEnglish": "string",
     "longEnglish": "string"
}
`}</code></pre>
    <p>{`The errorCode are custom API error codes explained here:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid API usage, Resource exists`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid credentials`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forbidden`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid URI`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`410`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource deprecated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid password format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`601`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Merge of customers failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`602`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer with this email is already registered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`603`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid email`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`604`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid post code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`605`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid telephone number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`606`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`610`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`611`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UnAuthenticated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`613`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cannot restore the customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`614`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The customer is already active`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`617`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`618`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Failed to save resource`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`619`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SQL error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`621`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is a personnel ticket customer and can't be deleted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`624`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer account alias already exists`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "benefits"
    }}>{`Benefits`}</h2>
    <p>{`The other endpoints will be returning errors in the following format. As mentioned, this is the format we will use going forward with the API(new major versions).`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "timestamp": "string" // When the error occurred
    "status" : "string",  // The http status code
    "error": "string",    // The http status reason
    "path": "string",     // The request URI
    "message": "string",  // The error message
    "errors": "list"      // Optional list of error specifications
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      